<script>
/* eslint-disable */
</script>
<template>
  <v-app>
  <div>
      <div class="container">
        <div class="row">
          <v-btn @click="banksDetail = true, updateDetails(date)">Banks History</v-btn>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4">
                    
                    <table style="width:100%">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    <h3>Safe</h3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <v-card v-for="(safe, index) in safes" :key="index" @click="closeSafe()"
                        class="mx-auto my-3" color="#BAE1BE" light max-width="200">
                        <v-card-title>
                            <span class="title font-weight-bold">{{ safe.amount }} GEL</span>
                        </v-card-title>

                        <v-card-actions>
                            <v-list-item class="grow">
                                <v-list-item-avatar color="grey darken-3">
                                    <i class="material-icons md-36" >
                                        face
                                    </i>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>{{ safe.branch }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-actions>

                      <v-expand-transition>
                        <v-card v-if="safeFormDialog">
                          <v-card-title>
                            <span class="headline">Safe</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-text-field
                                    label="Amount"
                                    v-model="posAmount"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  md="8"
                                >
                                  <v-text-field
                                    label="Optional Comment"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-btn
                                    elevation="2"
                                    x-large
                                    color="grey"
                                    class="white--text"
                                    @click="safeFormDialog = false"
                                  >Close</v-btn>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-btn

                                    elevation="2"
                                    x-large
                                    class="white--text"
                                    color="green"
                                    @click="addToSafe()"
                                  >ADD</v-btn>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                  <v-btn
                                    elevation="2"
                                    color="blue"
                                    x-large
                                    class="white--text"
                                    @click="dropFromSafe()"
                                  >DROP</v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
            </div>
            <!-- End of safe -->
            <div class="col-lg-4 col-md-4">
                     <table style="width:100%">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    <h3>POS</h3>
                                </td>
                                <td style="width:50%; text-align:right" v-if="tillsCount == 0">
                                  <i class="material-icons md-36" @click="addTill()">add</i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <v-card v-for="(till, index) in tills.current" :key="index"  
                        class=" my-3 cols" color="#FE9A53" light max-width="250" @click="tillInfo(till)">
                        <v-card-title>
                            <span class="title font-weight-bold" v-if="Number(till.cash) + Number(till.glovo_cash) < 650">{{ (Number(till.cash) + Number(till.card) + Number(till.glovo_cash)).toFixed(2) }} Gel - {{ till.name }}</span>
                            <span class="title font-weight-bold" v-if="Number(till.cash) + Number(till.glovo_cash) >= 650"><span style="color: red;">DROP NEEDED {{ (Number(till.cash) + Number(till.card) + Number(till.glovo_cash)).toFixed(2) }} Gel </span>  - {{ till.name }}</span>
                        </v-card-title>

                        <v-card-actions>
                            <v-list-item class="grow">
                                <v-list-item-avatar color="grey darken-3" @click="tillFoo(till)">
                                    <i class="material-icons md-36">
                                        face
                                    </i>
                                </v-list-item-avatar>
                               
                                <v-list-item-content>
                                    <v-list-item-title>{{ till.name }} - {{ till.branch_name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>

                    <v-card v-for="(till, index) in tills.unclose" :key="index"  
                        class=" my-3 cols" color="#FE3853" light max-width="250" @click="tillInfo(till)">
                        <v-card-title>
                            <span class="title font-weight-bold" v-if="Number(till.cash) + Number(till.glovo_cash) < 650">{{ (Number(till.cash) + Number(till.card) + Number(till.glovo_cash)).toFixed(2) }} Gel - {{ till.name }}</span>
                            <span class="title font-weight-bold" v-if="Number(till.cash) + Number(till.glovo_cash) >= 650"><span style="color: red;">DROP NEEDED {{ (Number(till.cash) + Number(till.card) + Number(till.glovo_cash)).toFixed(2) }} Gel </span>  - {{ till.name }}</span>
                        </v-card-title>

                        <v-card-actions>
                            <v-list-item class="grow">
                                <v-list-item-avatar color="grey darken-3" @click="tillFoo(till)">
                                    <i class="material-icons md-36">
                                        face
                                    </i>
                                </v-list-item-avatar>
                               
                                <v-list-item-content>
                                    <v-list-item-title>{{ till.name }} - {{ till.branch_name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>
                </div>
                <!-- End of Tills -->
                <div class="col-lg-4 col-md-4">
                    <table style="width:100%">
                        <tbody>
                            <tr>
                                <td style="width:50%">
                                    <h3>Drivers</h3>
                                </td>
                                <td style="width:50%; text-align:right">
                                    <span class="mdi mdi-plus-thick"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                      <v-card v-for="(drv, index) in drivers" :key="index" class="mx-auto my-3" color="#46BDF2" light max-width="250" @click="driverInfo(drv)">
                          <v-card-title>
                              <span class="title font-weight-bold" v-if="drv.amount < 200">{{ (Number(drv.amount) + Number(drv.card)).toFixed(2)  }}  - {{ drv.username }}</span>
                              <span class="title font-weight-bold" v-if="drv.amount >= 200"><span style="color: red;" >DROP NEEDED {{ (Number(drv.amount) + Number(drv.card)).toFixed(2) }} </span>  - {{ drv.username }}</span>
                          </v-card-title>
                          
                          <v-card-actions>
                              <v-list-item class="grow">
                                
                                  <v-list-item-avatar color="grey darken-3">
                                      <i class="material-icons md-36">
                                          face
                                      </i>
                                  </v-list-item-avatar>
                              </v-list-item>
                          </v-card-actions>

                          <v-expand-transition v-if="driverID === drv.id">
                              <v-card
                                v-if="driverFormDialog"
                                light max-width="400"
                                class="transition-fast-in-fast-out v-card--reveal"
                                style="height: 100%;"
                              >
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-text-field
                                          v-model="driverAmount"
                                          label="Amount"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="12"
                                        md="8"
                                      >
                                        <v-text-field
                                          label="Optional Comment"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          x-large
                                          color="grey"
                                          class="white--text"
                                          @click="driverFormDialog = false"
                                        >Close</v-btn>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          x-large
                                          class="white--text"
                                          color="green"
                                          @click="addToDriver()"
                                        >ADD</v-btn>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          color="blue"
                                          x-large
                                          class="white--text"
                                          @click="dropFromDriver()"
                                        >DROP</v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                              </v-card>
                            </v-expand-transition>
                      </v-card>
                      
                      <v-card v-for="(drv, index) in uncloseDrivers" :key="index" class="mx-auto my-3" color="#EAE4D2" light max-width="250" @click="driverInfo(drv)">
                          <v-card-title>
                              <span class="title font-weight-bold">{{ drv.amount }}  - {{ drv.username }}</span>
                          </v-card-title>

                          <v-card-actions>
                              <v-list-item class="grow">
                                
                                  <v-list-item-avatar color="grey darken-3">
                                      <i class="material-icons md-36">
                                          face
                                      </i>
                                  </v-list-item-avatar>
                              </v-list-item>
                          </v-card-actions>

                          <v-expand-transition>
                              <v-card
                                v-if="driverFormDialog"
                                light max-width="400"
                                class="transition-fast-in-fast-out v-card--reveal"
                                style="height: 100%;"
                              >
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-text-field
                                          v-model="driverAmount"
                                          label="Amount"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="12"
                                        md="8"
                                      >
                                        <v-text-field
                                          label="Optional Comment"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          x-large
                                          color="grey"
                                          class="white--text"
                                          @click="driverFormDialog = false"
                                        >Close</v-btn>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          x-large
                                          class="white--text"
                                          color="green"
                                          @click="addToDriver()"
                                        >ADD</v-btn>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                      >
                                        <v-btn
                                          elevation="2"
                                          color="blue"
                                          x-large
                                          class="white--text"
                                          @click="dropFromDriver()"
                                        >DROP</v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                              </v-card>
                            </v-expand-transition>
                      </v-card>

                </div>
        </div>
      </div>
      
      <v-dialog
        v-model="safeDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Manager -> Safe</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                >
                <v-autocomplete
                  :items="driverList"
                  :filter="customFilter"
                  color="white"
                  item-text="username"
                  label="Manager"
                ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="Amount"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="8"
                >
                  <v-text-field
                    label="Optional Comment"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    color="grey"
                    class="white--text"
                    @click="safeDialog = false"
                  >Close</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                  >ADD</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                  >DROP</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      
      <v-dialog
        v-model="tillDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">ADD POS</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-form>
                  <v-select :items="allPoses" item-text="name" item-value="id" v-model="selectedPos" :rules="[v => !!v || 'Driver Not Selected First']" label="Select POS First" required></v-select>

                  <v-text-field label="Enter Amount" v-model="posAmount" :rules="exampleRules"></v-text-field>

                  <v-text-field clearable label="Comment" v-model="posComment" value="" hint="Some comment"></v-text-field>
                </v-form>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                    @click="addPos()"
                  >ADD</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="driverDialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Driver</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="8"
                >
                <v-autocomplete
                  :items="driverList"
                  :filter="customFilter"
                  color="white"
                  item-text="username"
                  label="Manager"
                ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="Amount"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="8"
                >
                  <v-text-field
                    label="Optional Comment"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    color="grey"
                    class="white--text"
                    @click="driverDialog = false"
                  >Close</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                  >ADD</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                  >DROP</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="safeCloseDialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Close Day - Safe - {{ safes[0].amount }} GEL</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <span class="headline">Working Cash - {{ safes[0].default_amount }} GEL</span>
              </v-row>
              <v-row>
               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Amount"
                  v-model="safeAmount"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="8"
              >
                <v-text-field
                  v-model="safeCloseComment"
                  label="Optional Comment"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-row>
                <v-col
                  v-for="(bank, index) in banks"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    class="white--text mx-5"
                    color="green"
                    @click="addToSafe(bank)"
                  >ADD FROM {{ bank.name }}</v-btn>
                </v-col>
              </v-row>

              <v-row class="my-5">
                <v-col
                  v-for="(bank, index) in banks"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text mx-5"
                    @click="dropFromSafe(bank)"
                  >DROP TO {{ bank.name }}</v-btn>
                </v-col>
              </v-row>
              <v-row class="my-5">
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text mx-5"
                    @click="safeExpense('expense')"
                  >Expense</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                <v-btn
                  elevation="2"
                  color="blue"
                  x-large
                  class="white--text mx-5"
                  @click="safeExpense('taxi')"
                >Taxi</v-btn>
                </v-col>
              </v-row>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="tillCloseDialog"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Close Day - {{ selectedPos.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2" class="justify-end">&nbsp;</v-col>
                <v-col cols="4 h4">Cash: {{ (Number(selectedPos.cash) + Number(selectedPos.glovo_cash)).toFixed(2) }}</v-col>
                <v-col cols="6"><v-text-field label="Cash Amount * " v-model="cashActual"></v-text-field></v-col>
              </v-row>

              <v-row>
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="4 h4">Card: {{ (Number(selectedPos.card) + Number(selectedPos.glovo_card)).toFixed(2) }}</v-col>
                <v-col cols="6"><v-text-field label="Card Amount * " v-model="cardActual"></v-text-field></v-col>
              </v-row>  
              <v-row>
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="4 h4">Total: {{ (Number(selectedPos.card) + Number(selectedPos.cash) + Number(selectedPos.glovo_card) + Number(selectedPos.glovo_cash)).toFixed(2) }}</v-col>
                <v-col cols="6 h4">{{ (Number(cashActual) + Number(cardActual)).toFixed(2) }}</v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="4 h4">Glovo: {{ selectedPos.glovo }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="4 h4">Glovo Cash: {{ selectedPos.glovo_cash }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="4 h4">Glovo Card: {{ selectedPos.glovo_card }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="4 h4">Wolt: {{ selectedPos.wolt_card }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="4 h4">Daily Sales: {{ (Number(selectedPos.card) + Number(selectedPos.cash) + Number(selectedPos.glovo_card) + Number(selectedPos.glovo) + Number(selectedPos.wolt_card) + Number(selectedPos.glovo_cash)).toFixed(2) }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="2">&nbsp;</v-col>
                <v-col cols="10">
                  <v-text-field label="Note if short or over" v-model="safeCloseComment"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="posAmount"
                    label="Amount"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="8"
                >
                  <v-text-field
                    label="Optional Comment"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    elevation="2"
                    x-large
                    color="grey"
                    class="white--text"
                    @click="reconcilePos()"
                  >Close</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    v-if="tillsCount == 0"
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                    @click="addToPos()"
                  >ADD</v-btn>
                  <v-btn
                    v-if="tillsCount != 0"
                    disabled
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                    @click="addToPos()"
                  >ADD</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    v-if="tillsCount == 0"
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                    @click="dropFromPos()"
                  >DROP</v-btn>
                  <v-btn
                    v-if="tillsCount != 0"
                    disabled
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                    @click="dropFromPos()"
                  >DROP</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="driverCloseDialog"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Close Day - {{ selectedDriver.username }}</span>
            <v-btn class="mx-5" @click="driverDetailsModal()"> Driver Details </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3" class="justify-end">&nbsp;</v-col>
                <v-col cols="3 h4">Cash: {{ Number(selectedDriver.amount).toFixed(2) }}</v-col>
                <v-col cols="6"><v-text-field label="Cash Amount" v-model="driverCashActual"></v-text-field></v-col>
              </v-row>

              <v-row>
                <v-col cols="3">&nbsp;</v-col>
                <v-col cols="3 h4">Card: {{ Number(selectedDriver.card).toFixed(2) }}</v-col>
                <v-col cols="6"><v-text-field label="Card Amount" v-model="driverCardActual"></v-text-field></v-col>
              </v-row>  
              <v-row>
                <v-col cols="3">&nbsp;</v-col>
                <v-col cols="3 h4">DRIVER TIP: {{ Number(selectedDriver.tip).toFixed(2) }}</v-col>
                <v-col cols="6">&nbsp;</v-col>
              </v-row>  
              <v-row>
                <v-col cols="3">&nbsp;</v-col>
                <v-col cols="3 h4">Total: {{ (Number(selectedDriver.amount) + Number(selectedDriver.card)).toFixed(2) }}</v-col>
                <v-col cols="6 h4">{{ (Number(driverCashActual) + Number(driverCardActual)).toFixed(2) }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">&nbsp;</v-col>
                <v-col cols="3 h4">Total Orders: {{ selectedDriver.count }}</v-col>
                <v-col cols="6 h4">&nbsp;</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">&nbsp;</v-col>
                <v-col cols="9">
                  <v-text-field label="Note if short or over" v-model="driverCloseComment"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="driverAmount"
                    label="Amount"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="8"
                >
                  <v-text-field
                    label="Optional Comment"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    v-if="selectedDriver.closed == null"
                    elevation="2"
                    x-large
                    color="grey"
                    class="white--text"
                    @click="reconcileDriver()"
                  >Close</v-btn>
                  <v-btn
                    v-if="selectedDriver.closed != null"
                    elevation="2"
                    x-large
                    disabled
                    color="grey"
                    class="white--text"
                    @click="reconcileDriver()"
                  >Close</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    v-if="selectedDriver.closed == null"
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                    @click="addToDriver()"
                  >ADD</v-btn>
                  <v-btn
                    v-if="selectedDriver.closed != null"
                    disabled
                    elevation="2"
                    x-large
                    class="white--text"
                    color="green"
                    @click="addToDriver()"
                  >ADD</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-btn
                    v-if="selectedDriver.closed == null"
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                    @click="dropFromDriver()"
                  >DROP</v-btn>
                  <v-btn
                    v-if="selectedDriver.closed != null"
                    disabled
                    elevation="2"
                    color="blue"
                    x-large
                    class="white--text"
                    @click="dropFromDriver()"
                  >DROP</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="banksDetail"
        max-width="1000px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Banks Details</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                    
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">

                <v-tabs
                  v-model="tab"
                  fixed-tabs
                  background-color="black"
                  dark      
                >
                  <v-tab
                      v-for="(item, index) in items"
                      :key="index"
                      @click="getTab(item)"
                  >
                      {{ item.tab }}
                  </v-tab>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <export-excel
                      :data = "detailedInfo"
                      :name = "'closeDetails.xls'" >
                      Download Data
                      <span class="material-icons">
                        get_app
                      </span>
                  </export-excel>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Difference Cash
                          </th>
                          <th class="text-left">
                            Difference Card
                          </th>
                          <th class="text-left">
                            Comment
                          </th>
                          <th class="text-left">
                            Close Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in detailedInfo"
                          :key="index"
                        >
                          <td v-if="item.driver_id == 0">{{ item.pos_name }}</td>
                          <td v-if="item.pos_id == null">{{ item.username }}</td>
                          <td>{{ item.diff_cash }}</td>
                          <td>{{ item.diff_card }}</td>
                          <td>{{ item.comment }}</td>
                          <td>{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>

                <v-tab-item>
                  <export-excel
                      :data = "detailedInfo"
                      :name = "'posDetails.xls'" >
                      Download Data
                      <span class="material-icons">
                        get_app
                      </span>
                  </export-excel>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            POS
                          </th>
                          <th class="text-left">
                            Method
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                          <th class="text-left">
                            Amount
                          </th>
                          <th class="text-left">
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in detailedInfo"
                          :key="index"
                        >
                          <td>{{ item.name }}</td>
                          <td>{{ item.payment_method }}</td>
                          <td>{{ item.action }}</td>
                          <td>{{ item.amount }}</td>
                          <td>{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>

                <v-tab-item>
                  <export-excel
                      :data = "detailedInfo"
                      :name = "'safeDetails.xls'" >
                      Download Data
                      <span class="material-icons">
                        get_app
                      </span>
                  </export-excel>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Action
                          </th>
                          <th class="text-left">
                            Amount
                          </th>
                          <th class="text-left">
                            Comment
                          </th>
                          <th class="text-left">
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in detailedInfo"
                          :key="index"
                        >
                          <td>{{ item.payment }}</td>
                          <td>{{ item.amount }}</td>
                          <td>{{ item.comment }}</td>
                          <td>{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>

                <v-tab-item>
                  <!-- <export-excel
                      :data = "groupDetails"
                      :name = "'totalSales.xls'" >
                      Download Data
                      <span class="material-icons">
                        get_app
                      </span>
                  </export-excel> -->
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Cash
                          </th>
                          <th class="text-left">
                            Card
                          </th>
                          <th class="text-left">
                            Delivery card
                          </th>
                          <th class="text-left">
                            Delivery cash
                          </th>
                          <th class="text-left">
                            Web Sales
                          </th>
                          <th class="text-left">
                            Invoices
                          </th>
                          <th class="text-left">
                            Wolt
                          </th>
                          <th class="text-left">
                            Sum Cash
                          </th>
                          <th class="text-left">
                            Sum Card
                          </th>
                          <th class="text-left">
                            Sum
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ orderDetails.cash }}</td>
                          <td>{{ orderDetails.card }}</td>
                          <td>{{ driverTotals.card }}</td>
                          <td>{{ driverTotals.cash }}</td>
                          <td>{{ orderDetails.opay }}</td>
                          <td>{{ orderDetails.invoice }}</td>
                          <td>{{ orderDetails.wolt }}</td>
                          <td>{{ orderDetails.sumCash }}</td>
                          <td>{{ orderDetails.sumCard }}</td>
                          <td>{{ orderDetails.sumAll }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
              </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      
      </v-dialog>
      <v-dialog
        v-model="driverDetail"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ selectedDriver.usenrname }} Details</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="2">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateDriver"
                      label="Select Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDriver"
                    @input="menu2 = false"
                    
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8">
                  <export-excel
                      :data = "detailedInfo"
                      :name = "'driverDetails.xls'" >
                      Download Data
                      <span class="material-icons">
                        get_app
                      </span>
                  </export-excel>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                          <th class="text-left">
                            Amount
                          </th>
                          <th class="text-left">
                            tip
                          </th>
                          <th class="text-left">
                            Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in detailedInfo"
                          :key="index"
                        >
                          <td>{{ item.name }}</td>
                          <td>{{ item.action }}</td>
                          <td>{{ item.amount }}</td>
                          <td>{{ item.tip }}</td>
                          <td>{{ item.created_at }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    <v-bottom-sheet v-model="closeError">
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-btn
            class="mt-6"
            text
            color="red"
            @click="closeError = !closeError"
          >
            close
          </v-btn>
          <div class="py-3">
            {{ closeErrorData }}
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-app>
</template>

<script>
/* eslint-disable */
import orderModal from  "../pages/Modal";
import axios from "axios";
import Vue from 'vue'
import excel from 'vue-excel-export'
 
Vue.use(excel)

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      items: [
          { tab: 'Closed', content: 'close' },
          { tab: 'POS Details', content: 'pos' },
          { tab: 'Safe Details', content: 'safe' },
          { tab: 'POS Total', content: 'total' },
          // { tab: 'Driver Details', content: 'driver' },
      ],
      tab: 0,
      closeError: false,
      closeErrorData: "",
      date: new Date(),
      dateDriver: new Date(),
      detailedInfo: [],
      menu: false,
      menu2: false,
      posID: '',
      driverID: '',
      selectedPos: [],
      banksDetail: false,
      driverDetail: false,
      pickedDate: null,
      selectedDriver: {},
      safeCash: 0,
      safeCard: 0,
      safeGlovo: 0,
      safeWolt: 0,
      safeTotal: null,
      cashActual: null,
      cardActual: null,
      driverCashActual: null,
      driverCardActual: null,
      totalActual: null,
      posAmount: 150,
      driverAmount: 40,
      safeAmount: null,
      posComment: '',
      safeCloseComment: '',
      safeDropComment: '',
      driverCloseComment: '',
      safeCloseDialog: false,
      safeFormDialog: false,
      tillCloseDialog: false,
      tillFormDialog: false,
      driverFormDialog: false,
      driverCloseDialog: false,
      openedTill: {},
      safes: ['a'],
      tills: { unclose: [], current: [] },
      posList: {},
      tillsCount: null,
      allPoses: [],
      banks: [],
      drivers: [],
      uncloseDrivers: [],
      valid: true,
      name: '',
      amountRules: [
          v => !!v || 'Emount not entered',
      ],
      exampleRules: [
          v => !!v || "Emount can't be blank",
          v => (v && v >= 0) || "Loan should be above 0",
          v => (v && v <= 500) || "Max should not be above 500",
      ],
      e6: 1,
      loggedUser: {},
      orders: [],
      orderDetails: [],
      driverTotals: {},
      groupDetails: {},
      old: false,
      singleSelect: true,
      selected: [],
      search: '',
      driverIndex: -1,
      balance: '',
      selectedItem: -1,
      loggedUserFull: [],
      defaultBalance: [
        { balance: 'pos', amount: 150 },
        { balance: 'driver', amount: 40 },
      ],
      driverList: [],
      loading: true,
      options: {},
      branch: 'saburtalo',
      // branchId: 2,
      status: '1,2,3,4,5,6,7,8,9',
      today: '', 
      safeDate: '2020-12-22',
      driverLoad: true,
      safeDialog: false,    
      tillDialog: false,    
      driverDialog: false,    
      dialog2: false,
      dialog3: false,
      notifications: false,
      sound: true,
      widgets: false,
      page: 0,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: "ORDER ID",
          align: "start",
          sortable: false,
          value: "order_id",
        },
        { text: "Branch", value: "branch" },
        { text: "Source", value: "source" },
        { text: "Delivery Adress", value: "order_data.adress" },
        { text: "Customer Name", value: "order_data.customer.name" },
        { text: "Customer Phone", value: "order_data.customer.tel" },
        { text: "Order Items", value: "order_data.items[0].name" },
      ],
    };
  },
  components: {
    orderModal
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
       if (vm.$store.state.auth.user.data.role.toLowerCase() == "admin" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "vicemanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "globalmanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "branchmanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "weiser") {
         vm.$router.push({name: "banks"}).catch(()=>{});
       }
       else {
         vm.$router.push({name: "dashboard"}).catch(()=>{});
       }
    });
  },
  mounted() {
    this.date = this.formatDate(this.date);
    this.dateDriver = this.formatDate(this.dateDriver);
    this.loggedUserFull = JSON.parse(localStorage.getItem("loggedUserData"));

    console.log('Logged User Full: ', this.loggedUserFull);

    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    

    this.today = yyyy + '-' + mm + '-' + dd;

    this.loggedUser = JSON.parse(localStorage.getItem("loggedUserData"));
    this.branchId = this.$store.state.auth.user.data.branch_id;

      const TOKEN = this.loggedUser.token;
      this.getSafes();

      this.getPoses();

      this.getDrivers();

      this.getBanks();

      this.updateDetails(this.date);

      this.totalDetails(this.date);

      var bodyFormPosAll = new FormData();
      bodyFormPosAll.set("branch_id", this.branchId);

    axios
      .request({
        method: "post",
        url:
          this.$hostname + "poses/list-by-branch",
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
        data: bodyFormPosAll,
      })
      .then((response) => {
        this.allPoses = response.data.data;
        console.log("All POS List: ", this.allPoses);
      });
  },
  created () {
        // this.timer = setInterval(this.getPoses, 5000)
    },
  watch: {
    date(val){
      if(this.tab == 0){
        this.updateDetails(val);
      }
      else if(this.tab == 1){
        this.posDetails(val);
      }
      else if(this.tab == 2){
        this.safeDetails(val);
      }
      else if(this.tab == 3){
        this.cardTotal = 0;
        this.cashTotal = 0;
        this.deliveryCashTotal = 0;
        this.deliveryCardTotal = 0;
        this.woltTotal = 0;
        this.glovoTransferTotal = 0;
        this.glovoCashTotal = 0;
        this.glovoCardTotal = 0;
        this.totalDetails(val);
      }
    },
    dateDriver(val) {
      this.driverDetails(val);
    }
  },
  computed: {
      filteredOrdersPos() {
      this.orders.forEach(x => {
          x.order_data = JSON.parse(x.order_data);
      });
      return this.orders.filter((x) => x.source === "pos" && x.order_data.deliveryMethod === 'Delivery');
    },
      filteredOrdersLegacy() {
      return this.orders.filter((x) => x.source === "Legacy");
    },
    selectedDefault() {
      if(this.selectedItem === -1){
        return 0;
      }
      else {
        this.balance =this.defaultBalance[this.selectedItem].amount;
        return this.balance;
      }
    },

    filteredDriver() {
        return this.driverList[this.driverIndex];
    },
  },
  methods: {
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
    getTab(tab){
      if(tab.content === 'close') {
        this.updateDetails(this.date);
      }
      else if(tab.content === 'pos') {
        this.posDetails(this.date);
      }
      else if(tab.content === 'safe') {
        this.safeDetails(this.date);
      }
      else if(tab.content === 'driver'){
        this.updateDetails(this.date);
      }
      else if(tab.content === 'total'){
        this.totalDetails(this.date);
      }
      this.$forceUpdate();
    },
    updateDetails(date){
      const TOKEN = this.loggedUser.token;
      if(date.length == 2){
        var dateString = date[0] + " to "+ date[1];
      }
      else if(date.length == 1) {
        var dateString = date[0] + " to "+ date[0];
      }
      else {
        var dateString = date + " to "+ date;
      }
      var bodyUpdate = new FormData();
      bodyUpdate.set("day", dateString);

      axios
      .request({
        method: "post",
        url:
          this.$hostname + "manager/get-close-day",
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
        data: bodyUpdate,
      }).then((response) => {
          this.detailedInfo = response.data.data;
          this.detailedInfo.forEach(x => {
            const date = new Date(x.created_at);
            //x.created_time = date.getHours() + ":" + date.getMinutes();
          })
      });
    },
    posDetails(date){
      const TOKEN = this.loggedUser.token;
      // var dateString = date + ' to '+ date;
      if(date.length == 2){
        var dateString = date[0] + " to "+ date[1];
      }
      else if(date.length == 1) {
        var dateString = date[0] + " to "+ date[0];
      }
      else {
        var dateString = date + " to "+ date;
      }
      var bodyUpdate = new FormData();
      bodyUpdate.set("day", dateString);

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/poses-detail",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyUpdate,
        })
        .then((response) => {
            this.detailedInfo = response.data.data;
            this.detailedInfo.forEach(x => {
              const date = new Date(x.created_at);
              //x.created_time = date.getUTCFullYear() + "-" + date.getHours() + ":" + date.getMinutes();
              if(x.amount < 0 && x.action != 'New order'){
                x.payment_method = 'Drop balance';
              }
            })
        });
    },
    totalDetails(date){
      
      const TOKEN = this.loggedUser.token;
      // var dateString = date + ' to '+ date;
      if(date.length == 2){
        var dateString = date[0] + " to "+ date[1];
      }
      else if(date.length == 1) {
        var dateString = date[0] + " to "+ date[0];
      }
      else {
        var dateString = date + " to "+ date;
      }

      var bodyFormDataDrv = new FormData();
      //bodyFormData.set("branch", this.branch);
      bodyFormDataDrv.set("branch_id", this.loggedUserFull.branch_id);
      bodyFormDataDrv.set("day", dateString);
      
      axios
        .request({
          method: "post",
          url:
            this.$hostname + "driver/sales",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormDataDrv,
        })
        .then((response) => {
        this.driverTotals = response.data.data;

        console.log('DRIVER SALES: ', this.driverTotals);

        });
      this.$forceUpdate();

      var bodyFormData = new FormData();
      //bodyFormData.set("branch", this.branch);
      bodyFormData.set("branch_id", this.loggedUserFull.branch_id);
      bodyFormData.set("day", dateString);
      
      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/sales",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormData,
        })
        .then((response) => {
          this.orderDetails = response.data.data;


          // this.orderDetails.glovo_cash = Number(this.orderDetails.glovo_cash);
          // this.orderDetails.glovo_card = Number(this.orderDetails.glovo_card);
          // this.orderDetails.glovo = Number(this.orderDetails.glovo);
          this.orderDetails.wolt = Number(this.orderDetails.wolt).toFixed(2);
          this.orderDetails.cash = Number(this.orderDetails.cash).toFixed(2);
          this.orderDetails.card = Number(this.orderDetails.card).toFixed(2);
          this.driverTotals.cash = Number(this.driverTotals.cash).toFixed(2);
          this.driverTotals.card = Number(this.driverTotals.card).toFixed(2);

          
          this.orderDetails.opay = Number(this.orderDetails.opay).toFixed(2)
          this.orderDetails.wolt = Number(this.orderDetails.wolt).toFixed(2)

          // this.orderDetails.invoice = '100.52';

          this.orderDetails.invoice = Number(this.orderDetails.invoice).toFixed(2)

          this.orderDetails.sumCash = (Number(this.orderDetails.cash) + Number(this.driverTotals.cash)).toFixed(2);
          this.orderDetails.sumCard = (Number(this.orderDetails.card) + Number(this.driverTotals.card)).toFixed(2);
          this.orderDetails.sumAll = (Number(this.orderDetails.sumCash) + Number(this.orderDetails.sumCard) + Number(this.orderDetails.wolt) + Number(this.orderDetails.invoice) + Number(this.orderDetails.opay)).toFixed(2);
          
          this.$forceUpdate();
          });

    },
    safeDetails(date){
      const TOKEN = this.loggedUser.token;
      //var dateString = date + ' to '+ date;
      if(date.length == 2){
        var dateString = date[0] + " to "+ date[1];
      }
      else if(date.length == 1) {
        var dateString = date[0] + " to "+ date[0];
      }
      else {
        var dateString = date + " to "+ date;
      }
      var bodyUpdate = new FormData();
      bodyUpdate.set("day", dateString);

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/safe-detail",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyUpdate,
        })
        .then((response) => {
            this.detailedInfo = response.data.data;
            this.detailedInfo.forEach(x => {
              const date = new Date(x.created_at);
              //x.created_time = date.getHours() + ":" + date.getMinutes();

                // if(x.driver_id == 0 && x.amount < 0) {
                //   // x.payment = 'Drop to ' + x.bank_name;
                //   x.payment = 'Add from Bank';
                // }
                // else if(x.driver_id == 0 && x.amount >= 0) {
                //   x.payment = 'Add from Bank';
                // }
            })
        });
    },
    driverDetailsModal() {
      this.driverDetails(this.dateDriver);
      this.driverDetail = true;
      this.driverCloseDialog = false;
    },
    driverDetails(date){
      const TOKEN = this.loggedUser.token;
      // var dateString = date + ' to '+ date;
      if(date.length == 2){
        var dateString = date[0] + " to "+ date[1];
      }
      else if(date.length == 1) {
        var dateString = date[0] + " to "+ date[0];
      }
      else {
        var dateString = date + " to "+ date;
      }
      var bodyUpdate = new FormData();
      bodyUpdate.set("day", dateString);
      // bodyUpdate.set("driver_id", this.selectedDriver.id);

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "driver/detail",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyUpdate,
        })
        .then((response) => {
            this.detailedInfo = response.data.data;
        });
    },
    
    reconcilePos(){
      var totalPosCash = (Number(this.selectedPos.cash) + Number(this.selectedPos.glovo_cash));
      var totalPosCard = (Number(this.selectedPos.card) + Number(this.selectedPos.glovo_card));
      if(this.cashActual == null || this.cardActual == null){
        alert('Enter Cash actual and Card actual before closing POS!');
      }
      // else if(totalPosCash != this.cashActual || totalPosCard != this.cardActual && this.safeCloseComment == ''){
      //   alert('POS Total and POS actual are not equal, please enter comment!');
      // }
      else {
        var r = confirm("Close POS for a day?");
        if(r == true){
          var diff_cash = (this.cashActual- totalPosCash).toFixed(2);
          var diff_card = (this.cardActual -totalPosCard).toFixed(2);
          const TOKEN = this.loggedUser.token;
          var reconcilePosForm = new FormData();
          reconcilePosForm.set("driver_id", 0);
          reconcilePosForm.set("pos_id", this.selectedPos.poses_id);
          reconcilePosForm.set("comment", this.safeCloseComment);
          reconcilePosForm.set("diff_cash", diff_cash);
          reconcilePosForm.set("diff_card", diff_card);

          axios
            .request({
              method: "post",
              url:
                this.$hostname + "manager/close-day",
              headers: {
                Authorization: "Bearer " + TOKEN,
              },
              data: reconcilePosForm,
            })
            .then((response) => {
              if(response.data.data == "0"){
                this.safeCloseComment = '';
                this.cashActual = '';
                this.cardActual = '';
                this.totalActual = '';
                this.tillCloseDialog = false;
                this.getSafes();
                this.getPoses();
                this.getDrivers();
                this.getBanks();
              } else {
                this.closeError = true;
                this.closeErrorData = response.data.data;
              }
            });
        }
      }
    },
    reconcileDriver(){
      var r = confirm("Close Driver for a day?");
      if(r == true){
        var diff_card = (this.driverCardActual - this.selectedDriver.card).toFixed(2);
        var diff_cash = (this.driverCashActual - this.selectedDriver.amount).toFixed(2);
        const TOKEN = this.loggedUser.token;
        var reconcileDriverForm = new FormData();
        reconcileDriverForm.set("driver_id", this.selectedDriver.id);
        reconcileDriverForm.set("pos_id", 0);
        reconcileDriverForm.set("comment", this.driverCloseComment);
        reconcileDriverForm.set("diff_card", diff_card);
        reconcileDriverForm.set("diff_cash", diff_cash);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "manager/close-day",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: reconcileDriverForm,
          })
          .then((response) => {
            this.driverCloseComment = '';
            this.driverCashActual = '';
            this.driverCardActual = '';
            this.totalActual = '';
            this.driverCloseDialog = false;

            this.getSafes();

            this.getPoses();

            this.getDrivers();

            this.getBanks();
          });
      }
      else {

      }
    },
    getSafes(){
      const TOKEN = this.loggedUser.token;
      var bodyFormSafe = new FormData();
      bodyFormSafe.set("branch_id", this.branchId);
      bodyFormSafe.set("day", this.today);
      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/get-safes",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormSafe,
        })
        .then((response) => {
          this.safes = response.data.data;
        });
    },
    getPoses(){
      const TOKEN = this.loggedUser.token;
      var bodyFormPos = new FormData();
      bodyFormPos.set("branch_id", this.branchId);
      bodyFormPos.set("day", this.today);

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/get-poses",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormPos,
        })
        .then((response) => {
          this.tills = response.data.data;

          

          this.tillsCount = this.tills.unclose.length;
          // this.tillsCount = 1;


        }).catch((error) => {
          console.log("getPosses error: ", error);
        });
    },

    getDrivers(){
      const TOKEN = this.loggedUser.token;
      var bodyFormDriver = new FormData();
      bodyFormDriver.set("branch", 'digomi');

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "driver/clockedin-drivers",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormDriver,
        })
        .then((response) => {
          this.drivers = response.data.data;
        }).catch((error) => {
          console.log('getDrivers error: ', error);
        });

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "driver/unclose-drivers",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyFormDriver,
        })
        .then((response) => {
          this.uncloseDrivers = response.data.data;
        }).catch((error) => {
          console.log('getDrivers error: ', error);
        });
    },
    
    getBanks(){
      const TOKEN = this.loggedUser.token;
      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/bank-list",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          this.banks = response.data.data;
        });
    },
    addPos(){
      const TOKEN = this.loggedUser.token;
      var bodyAddPos = new FormData();
      bodyAddPos.set("pos_id", this.selectedPos);
      bodyAddPos.set("amount", this.posAmount);
      bodyAddPos.set("safe_id", this.safes[0].id);

      axios
        .request({
          method: "post",
          url:
            this.$hostname + "poses/edit-balance",
          headers: {
            Authorization: "Bearer " + TOKEN,
          },
          data: bodyAddPos,
        })
        .then((response) => {
          
          this.tillDialog = false;
          this.tillCloseDialog = false;
          this.getSafes();
          this.getPoses();
          this.getDrivers();
          this.posAmount = 0;
        });
    },
    addToPos(){
      var r = confirm("Add "+ this.posAmount + " to POS?");
      if(r == true){
        const TOKEN = this.loggedUser.token;
        var bodyAddPosBalance = new FormData();
        bodyAddPosBalance.set("pos_id", this.posID);
        bodyAddPosBalance.set("amount", this.posAmount);
        bodyAddPosBalance.set("safe_id", this.safes[0].id);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "poses/edit-balance",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: bodyAddPosBalance,
          })
          .then((response) => {
            
            this.tillFormDialog = false;
            this.tillCloseDialog = false;
            this.getSafes();
            this.getPoses();
            this.getDrivers();
            this.posDetails(this.date);
            this.posAmount = 0;
          });
      }
    },
    dropFromPos(){
      var r = confirm("Drop "+ this.posAmount + " From POS?");
      if(r == true){

        if(Number(this.selectedPos.cash) + Number(this.selectedPos.glovo_cash) < this.posAmount){
            alert('There is not that amount of money in POS');
        }
        else {
          var r = confirm("Drop from POS?");
          if(r == true){
            const TOKEN = this.loggedUser.token;
            var bodyDropSafe = new FormData();
            bodyDropSafe.set("pos_id", this.posID);
            bodyDropSafe.set("amount", -this.posAmount);
            bodyDropSafe.set("safe_id", this.safes[0].id);

            axios
              .request({
                method: "post",
                url:
                  this.$hostname + "poses/edit-balance",
                headers: {
                  Authorization: "Bearer " + TOKEN,
                },
                data: bodyDropSafe,
              })
              .then((response) => {
                
                this.tillFormDialog = false;
                this.tillCloseDialog = false;
                this.getSafes();
                this.getPoses();
                this.getDrivers();
                this.posDetails(this.date);
                this.posAmount = 0;
              });
          }
        }
      }
    },
    addToSafe(bank){
      var r = confirm("Add "+ this.safeAmount +" To Safe?");
      if(r == true){
      
        const TOKEN = this.loggedUser.token;
        var bodyAddSafeBalance = new FormData();
        bodyAddSafeBalance.set("amount", this.safeAmount);
        bodyAddSafeBalance.set("safe_id", this.safes[0].id);
        bodyAddSafeBalance.set("bank_id", bank.id);
        bodyAddSafeBalance.set("comment", this.safeCloseComment);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "poses/drop-safe-balance",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: bodyAddSafeBalance,
          })
          .then((response) => {
            
            this.safeCloseDialog = false;
            this.getSafes();
            this.getPoses();
            this.getDrivers();
            this.safeDetails(this.date);
            this.safeAmount = null;
            this.safeCloseComment = '';
          });
      }
    },
    dropFromSafe(bank){
      var r = confirm("Drop "+ this.safeAmount +" From Safe?");
      if(r == true){
        const TOKEN = this.loggedUser.token;
        var bodyDropSafeBalance = new FormData();
        bodyDropSafeBalance.set("amount", - this.safeAmount);
        bodyDropSafeBalance.set("safe_id", this.safes[0].id);
        bodyDropSafeBalance.set("bank_id", bank.id);
        bodyDropSafeBalance.set("comment", this.safeCloseComment);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "poses/drop-safe-balance",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: bodyDropSafeBalance,
          })
          .then((response) => {
            
            this.safeCloseDialog = false;
            this.getSafes();
            this.getPoses();
            this.getDrivers();
            this.safeDetails(this.date);
            this.safeAmount = null;
            this.safeCloseComment = '';
});
      }
    },
    safeExpense(type){
      var r = confirm( this.safeAmount +" Expense From Safe?");
      if(r == true){

        const TOKEN = this.loggedUser.token;
        var bodyExpemseSafe = new FormData();
        bodyExpemseSafe.set("amount", this.safeAmount);
        bodyExpemseSafe.set("comment", type + " - " + this.safeCloseComment);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "poses/expenses",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: bodyExpemseSafe,
          })
          .then((response) => {
            
            this.safeCloseDialog = false;
            this.getSafes();
            this.getPoses();
            this.getDrivers();
            this.safeDetails(this.date);
            this.safeAmount = null;
            this.safeCloseComment = '';
          });
      }
    },
    addToDriver(){
      var r = confirm("Add To "+this.selectedDriver.username+"?");
      if(r == true){
        const TOKEN = this.loggedUser.token;
        var bodyAddDriverBalance = new FormData();
        bodyAddDriverBalance.set("amount", this.driverAmount);
        bodyAddDriverBalance.set("driver_id", this.selectedDriver.id);

        axios
          .request({
            method: "post",
            url:
              this.$hostname + "driver/edit-balance",
            headers: {
              Authorization: "Bearer " + TOKEN,
            },
            data: bodyAddDriverBalance,
          })
          .then((response) => {
            
            this.driverCloseDialog = false;
            this.getSafes();
            this.getPoses();
            this.getDrivers();
            this.driverAmount = 0;
          });
      }
    },
    dropFromDriver(){
      
      if(this.driverAmount <= this.selectedDriver.amount){
        var r = confirm("Drop From "+this.selectedDriver.username+"?");
        if(r == true){
          const TOKEN = this.loggedUser.token;
          var bodyAddDriverBalance = new FormData();
          bodyAddDriverBalance.set("amount", - this.driverAmount);
          bodyAddDriverBalance.set("driver_id", this.selectedDriver.id);

          axios
            .request({
              method: "post",
              url:
                this.$hostname + "driver/edit-balance",
              headers: {
                Authorization: "Bearer " + TOKEN,
              },
              data: bodyAddDriverBalance,
            })
            .then((response) => {
              
              this.driverFormDialog = false;
              this.getSafes();
              this.getPoses();
              this.getDrivers();
              this.driverAmount = 0;
            });
          this.driverCloseDialog = false;
        }
      }
      else {
        alert('You Cant drop more than driver cash amount!');
      }
    },
    addSafe() {
      this.safeDialog = true;
      // this.safes.push(this.safe);
    },
    closeSafe(){

      this.safeAmount = (Number(this.safes[0].amount) - Number(this.safes[0].default_amount)).toFixed(2);

      this.safeCloseDialog = true;
      
    },
    addTill() {
      this.tillDialog = true;
      // this.tills.push(this.till);
    },
    addDriver() {
      this.driverDialog = true;
      this.drivers.push(this.driver);
    },
    tillFoo(pos){
      this.tillFormDialog = true;
      this.posID = pos.poses_id;
    },
    tillInfo(pos){
      this.selectedPos = pos;
      this.tillCloseDialog = true;
      this.posID = pos.poses_id;
    },
    driverInfo(driver){
      this.selectedDriver = driver;
      this.driverCloseDialog = true;
    },
    driverFoo(driver){
      this.driverFormDialog = true;
      this.driverID = driver.id;
    },
    setVal() {
      setTimeout(() => this.balance = this.defaultBalance[this.selectedItem].amount, 100)
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("`data-tab`"));
    },

    customFilter (item, queryText, itemText) {
        const textOne = item.username.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },

      onlyNumber (evt) {
        evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();         

          } else {  
            return true;
          }
      },


  },
};
</script>
